function initArrayList(arrayForSort) {
    let cities = [...arrayForSort];

    cities = sortCities(cities);



    let globalCitiesList = [];
    let sectionCities = [];

    cities.forEach((city, key) => {
        const prevCity = cities[key - 1];

        const prevCityWord = prevCity?.city_name[0];
        const cityWord = city?.city_name[0];

        if (prevCityWord === cityWord || key === 0) {
            sectionCities.push(city);
        } else {
            globalCitiesList.push({
                symbol: prevCityWord,
                items: sectionCities,
            });
            sectionCities = [];
            sectionCities = [city];
        }
    });



    if (sectionCities.length > 0) {
        globalCitiesList.push({
            symbol: sectionCities?.[0]?.city_name?.[0],
            items: sectionCities,
        });
    }



    return globalCitiesList;
}

function sortArray(arrayForSort) {
    let cities = [...arrayForSort]

    cities.sort((a, b) => {
        let nameA = (a?.name || "").replace(/[^a-zA-ZА-Яа-я0-9]+/g, "");
        let nameB = (b?.name || "").replace(/[^a-zA-ZА-Яа-я0-9]+/g, "");

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;

        return 0;
    });

    return cities;
}
export {
    initArrayList,
    sortArray,
}