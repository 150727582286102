import { render, staticRenderFns } from "./_.vue?vue&type=template&id=6d4b6b18&scoped=true&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"
import style0 from "./_.vue?vue&type=style&index=0&id=6d4b6b18&lang=scss&scoped=true&"
import style1 from "./_.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4b6b18",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsPage: require('/var/www/grizzlysms.com/release-2024.11.15-13:36:01/node_modules/sitis-nuxt/lib/cms/CmsPage.vue').default,CmsPage: require('/var/www/grizzlysms.com/release-2024.11.15-13:36:01/node_modules/sitis-nuxt/lib/cms/CmsPage.vue').default,Loader: require('/var/www/grizzlysms.com/release-2024.11.15-13:36:01/components/loader/Loader.vue').default})
